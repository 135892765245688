<script>
	import { siInstagram } from "simple-icons";

	/** @type {{color?: any, title?: any, size?: number}} */
	let { color = siInstagram.hex, title = siInstagram.title, size = 24 } = $props();
</script>

<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={size} height={size} fill={color}>
	<title>{title}</title>
	<path d={siInstagram.path} />
</svg>
